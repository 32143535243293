import React, { useState, useEffect } from "react";
import "./Slider.css";

export const Slider = ({
  imgs = [],
  duration = 3000,
  hideIndicators = false,
  hideControls = false,
  control,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === imgs.length - 1 ? 0 : prevSlide + 1
      );
    }, duration);

    setTimer(interval);

    return () => clearInterval(interval);
  }, [imgs.length, duration]);

  const nextSlide = () => {
    resetTimer();
    setCurrentSlide((prevSlide) =>
      prevSlide === imgs.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    resetTimer();
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? imgs.length - 1 : prevSlide - 1
    );
  };

  const resetTimer = () => {
    clearInterval(timer);
    const newTimer = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === imgs.length - 1 ? 0 : prevSlide + 1
      );
    }, duration);
    setTimer(newTimer);
  };

  const defaultControl = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M17.66 7.93L12.71 12l4.95 4.95-1.41 1.41L9.88 12l6.37-6.37 1.41 1.4zM7.35 7.93L2.39 12l4.95 4.95L6.93 18.3 0 12 6.93 5.65l1.41 1.41z" />
    </svg>
  );

  return (
    <div className="custom-slider-container">
      <div
        className="custom-slider"
        style={{
          width: `${imgs.length * 100}%`,
          transform: `translateX(-${currentSlide * (100 / imgs.length)}%)`,
        }}
      >
        {imgs.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Slide ${index}`}
            className="custom-slide"
          />
        ))}
      </div>
      {!hideControls && (
        <>
          <button
            className="custom-slider-control custom-slider-control-left"
            onClick={prevSlide}
          >
            {control ? control : defaultControl}
          </button>
          <button
            className="custom-slider-control custom-slider-control-right"
            onClick={nextSlide}
          >
            {control ? control : defaultControl}
          </button>
        </>
      )}
      {!hideIndicators && (
        <div className="custom-slider-indicators">
          {imgs.map((_, index) => (
            <button
              key={index}
              className={`custom-slider-indicator ${
                index === currentSlide ? "active" : ""
              }`}
              onClick={() => {
                resetTimer();
                setCurrentSlide(index);
              }}
            ></button>
          ))}
        </div>
      )}
    </div>
  );
};
