import React from "react";
import { FaEnvelope, FaFacebookF, FaInstagram, FaPhone } from "react-icons/fa";
import logo from "../assets/goldcateringkragujevacmainlogo.png";
import { FooterButton } from "./FooterButton";

export const Footer = () => {
  return (
    <footer className="bg-white px-8 py-4">
      <div className="flex">
        <div className="mx-auto">
          <img
            src={logo}
            alt="Gold catering Kragujevac"
            className="h-[214px] w-[214px]"
          />
          <div className="flex space-x-4 mb-8">
            <FooterButton url="tel:0615388348">
              <FaPhone size={24} />
            </FooterButton>
            <FooterButton url="mailto:info@goldcatering.rs">
              <FaEnvelope size={24} />
            </FooterButton>
            <FooterButton url="https://www.facebook.com/goldcateringkg">
              <FaFacebookF size={22} />
            </FooterButton>
            <FooterButton url="https://www.instagram.com/goldcateringkg/?igsh=MTE3cW4xbWp5bHRqaA%3D%3D&utm_source=qr&fbclid=IwAR1ye-RpDdOjkebKZCaQVsu_L_0YdjKdGPx-fMx4gQMEskLlNhuY-q60Lec">
              <FaInstagram size={24} />
            </FooterButton>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center border-t pt-4">
        <p className="text-xs text-gray-600 mb-4 font-lexend">
          © 2024 Gold Catering{" "}
          <span className="hidden md:inline">Kragujevac</span>
        </p>

        <p className="text-xs text-gray-600 mb-4 font-lexend">
          Created by JJSolutions
        </p>
      </div>
    </footer>
  );
};
