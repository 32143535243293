export const FooterButton = ({ children, url }) => {
  return (
    <a
      href={url}
      className="text-gray-600 border border-gray-600 rounded-full p-2 hover:text-accent hover:border-accent"
    >
      {children}
    </a>
  );
};
