import React from "react";
import { Title } from "./Title";
import { Slider } from "./Slider";
import img1 from "../assets/slider/gazebotende.png";
import img2 from "../assets/slider/goldcateringkg.png";
import img3 from "../assets/slider/keteringkragujevac.png";

export const TendeDetail = () => {
  const dummyImgs = [img1, img2, img3];
  return (
    <div
      className="flex flex-col md:flex-row items-center justify-center bg-gray-100 p-8 py-16 md:h-screen"
      id="tende_detail"
    >
      <div className="md:w-1/2 md:pr-4">
        <Title title="Gazebo" rest="tende" />
        <p className="text-gray-600 text-lg mb-4 font-lexend my-8">
          Izdajemo gazebo tende za sve prilike kako bismo Vam pružili elegantan
          i funkcionalan prostor za Vaše događaje na otvorenom. Naše tende su
          visokog kvaliteta i prilagodljive različitim potrebama, a cenu
          izdajemo po upitu kako bismo osigurali da dobijete najbolju ponudu u
          skladu sa specifičnostima Vašeg događaja. Uz naše gazebo tende,
          stvaramo udobnu i zaštićenu atmosferu za Vaše goste, bilo da je reč o
          proslavama, manifestacijama ili drugim posebnim prilikama na otvorenom
          prostoru.
        </p>
        <div className="mb-12">
          <a
            href="tel:0615388348"
            className="border border-accent text-accent px-4 py-2 rounded font-medium hover:bg-accent hover:text-white"
          >
            061 53 88 348
          </a>
        </div>
      </div>
      <div className="w-full md:w-1/2 md:pl-4">
        <Slider imgs={dummyImgs} hideControls />
      </div>
    </div>
  );
};
