import React from "react";
import img1 from "../assets/slike/1.jpg";
import img2 from "../assets/slike/2.jpg";
import img19 from "../assets/slike/19.jpg";

export const Cards = () => {
  return (
    <div className="flex flex-wrap justify-center gap-4">
      <div className="max-w-sm rounded overflow-hidden shadow-lg m-4">
        <img
          className="w-full h-40 object-cover"
          src={img1}
          alt="Gold catering kragujevac"
        />
        <div className="px-6 py-4">
          <div className="font-medium text-xl mb-2 font-lexend">
            Rodjendani i slave
          </div>
          <p className="text-gray-700 text-base font-lexend">
            Rodjendani i slave su posebni trenuci u životu, težimo da svaki od
            ovih važnih događaja bude obogaćen ukusnom hranom i ugodnom
            atmosferom kako bi svaki gost uživao u nezaboravnom iskustvu.
          </p>
        </div>
      </div>

      <div className="max-w-sm rounded overflow-hidden shadow-lg m-4">
        <img
          className="w-full h-40 object-cover"
          src={img2}
          alt="Ketering za svadbe i proslave Kragujevac"
        />
        <div className="px-6 py-4">
          <div className="font-medium text-xl mb-2 font-lexend">
            Veridbe i venčanja
          </div>
          <p className="text-gray-700 text-base font-lexend">
            Veridbe i venčanja su važni koraci u ljubavnoj priči, težimo da
            učinimo ove posebne trenutke magičnim i nezaboravnim.
          </p>
        </div>
      </div>

      <div className="max-w-sm rounded overflow-hidden shadow-lg m-4">
        <img
          className="w-full h-40 object-cover"
          src={img19}
          alt="Ketering kragujevac"
        />
        <div className="px-6 py-4">
          <div className="font-medium text-xl mb-2 font-lexend">
            Poslovni dogadjaji
          </div>
          <p className="text-gray-700 text-base font-lexend">
            Naša ketering usluga za poslovne događaje kombinuje profesionalnost,
            kvalitetnu hranu i vrhunsku uslugu kako biste impresionirali
            partnere i klijente.
          </p>
        </div>
      </div>
    </div>
  );
};
