import React, { useState, useEffect, useCallback } from "react";
import { Title } from "./Title";
import img1 from "../assets/slike/1.jpg";
import img2 from "../assets/slike/2.jpg";
import img3 from "../assets/slike/3.jpg";
import img4 from "../assets/slike/4.jpg";
import img5 from "../assets/slike/5.jpg";
import img6 from "../assets/slike/6.jpg";
import img7 from "../assets/slike/7.jpg";
import img8 from "../assets/slike/8.jpg";
import img10 from "../assets/slike/10.jpg";
import img11 from "../assets/slike/11.jpg";
import img12 from "../assets/slike/12.jpg";
import img13 from "../assets/slike/13.jpg";
import img14 from "../assets/slike/14.jpg";
import img15 from "../assets/slike/15.jpg";
import img16 from "../assets/slike/16.jpg";
import img17 from "../assets/slike/17.jpg";
import img18 from "../assets/slike/18.jpg";
import img19 from "../assets/slike/19.jpg";
import img20 from "../assets/slike/20.jpg";
import img21 from "../assets/slike/21.jpg";
import img22 from "../assets/slike/22.jpg";
import img23 from "../assets/slike/23.jpg";
import img24 from "../assets/slike/24.jpg";
import img25 from "../assets/slike/25.jpg";
import img26 from "../assets/slike/26.jpg";
import img27 from "../assets/slike/27.jpg";

const imgs = [
  {
    url: img1,
    alt: "Gold Catering",
  },
  {
    url: img2,
    alt: "Gold Catering Kragujevac",
  },
  {
    url: img7,
    alt: "Ketering Kragujevac",
  },
  {
    url: img8,
    alt: "Ketering hrana Kragujevac",
  },
  {
    url: img10,
    alt: "Ketering za proslave",
  },
  {
    url: img26,
    alt: "Ketering za rodjendane",
  },
  {
    url: img27,
    alt: "Gold ketering",
  },
  {
    url: img11,
    alt: "Ketering kg",
  },
  {
    url: img3,
    alt: "Ketering hrana kg",
  },
  {
    url: img12,
    alt: "Hrana za rodjendan kragujevac",
  },
  {
    url: img13,
    alt: "Gold catering kg",
  },
  {
    url: img14,
    alt: "Gold catering rs",
  },
  {
    url: img15,
    alt: "Ketering hrana za sve vrste proslava",
  },
  {
    url: img16,
    alt: "Najbolji ketering",
  },
  {
    url: img17,
    alt: "Najbolji ketering kragujevac",
  },
  {
    url: img18,
    alt: "Najbolji ketering kg",
  },
  {
    url: img19,
    alt: "Ketering 034",
  },
  {
    url: img4,
    alt: "Hrana za proslave kragujevac",
  },
  {
    url: img20,
    alt: "Korporativni ketering",
  },
  {
    url: img21,
    alt: "Ketering za svadbe",
  },
  {
    url: img22,
    alt: "Ketering za slave",
  },
  {
    url: img23,
    alt: "Ketering za slave kragujevac",
  },
  {
    url: img6,
    alt: "Ketering za sve prilike kg",
  },
  {
    url: img24,
    alt: "Ketering za sve prilike kragujevac",
  },
  {
    url: img25,
    alt: "Ketering kragujevac hrana",
  },
  {
    url: img5,
    alt: "Hrana ketering kg",
  },
];

export const Gallery = () => {
  const [lightboxIndex, setLightboxIndex] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => setShowAll(true);

  const openLightbox = (index) => {
    setLightboxIndex(index);
  };

  const closeLightbox = () => {
    setLightboxIndex(null);
  };

  const goToPrev = useCallback(() => {
    if (lightboxIndex === 0) {
      setLightboxIndex(imgs.length - 1);
    } else {
      setLightboxIndex(lightboxIndex - 1);
    }
  }, [lightboxIndex]);

  const goToNext = useCallback(() => {
    if (lightboxIndex === imgs.length - 1) {
      setLightboxIndex(0);
    } else {
      setLightboxIndex(lightboxIndex + 1);
    }
  }, [lightboxIndex]);

  const shownImgs = showAll ? imgs : imgs.slice(0, 12);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        lightboxIndex !== null && closeLightbox();
      }
      if (event.keyCode === 37) {
        // Left arrow key
        lightboxIndex !== null && goToPrev();
      } else if (event.keyCode === 39) {
        // Right arrow key
        lightboxIndex !== null && goToNext();
      }
    };

    const handleClickOutside = (event) => {
      if (event.target.classList.contains("lightbox")) {
        closeLightbox();
      }
    };

    document.addEventListener("keydown", handleEscape);
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [goToNext, goToPrev, lightboxIndex]);

  return (
    <div className="px-8">
      <Title title="Naša" rest="galerija" className="pt-4" />
      <p className="text-gray-600 text-lg font-lexend my-8">
        Pogledajte naše najnovije kreacije i inspirišite se za Vaš sledeći
        događaj. Gold Catering kombinuje tradiciju sa modernim pristupom kako bi
        svaki događaj bio nezaboravan. Naša galerija prikazuje širok spektar
        kulinarskih i dekorativnih ideja, prilagođenih različitim temama i
        stilovima, što Vam omogućava da pronađete savršenu inspiraciju za Vaš
        specijalni trenutak. Uz pažljivo osmišljene detalje i visokokvalitetne
        sastojke, svaka kreacija iz naše galerije predstavlja spoj estetike,
        ukusa i iskustva koje će doprineti nezaboravnom doživljaju vašeg
        događaja.
      </p>
      <div className="grid grid-cols-1 grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 lg:gap-8">
        {shownImgs.map((img, index) => (
          <div
            key={index}
            className="h-[150px] sm:h-[200px] md:h-[250px] lg:h-[300px] object-cover"
          >
            <img
              src={img.url}
              alt={img.alt}
              className="cursor-pointer block w-full h-full sm:max-w-xs md:max-w-none object-cover"
              onClick={() => openLightbox(index)}
            />
          </div>
        ))}
      </div>
      {!showAll && (
        <div className="flex justify-center">
          <button
            className="my-8 px-4 py-2 bg-black border border-white hover:bg-accent font-medium rounded z-10 h-12 text-white"
            onClick={handleShowAll}
          >
            Prikaži sve
          </button>
        </div>
      )}
      {lightboxIndex !== null && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={closeLightbox}
        >
          <div
            className="relative"
            onClick={(event) => event.stopPropagation()}
          >
            <button
              className="fixed top-0 right-0 m-4 text-white text-3xl"
              onClick={closeLightbox}
            >
              &times;
            </button>
            <img
              src={imgs[lightboxIndex].url}
              alt={imgs[lightboxIndex].alt}
              className="max-w-full max-h-full"
            />
            <button
              className="absolute top-0 left-0 flex items-center justify-center text-white text-3xl w-1/2 h-full bg-transparent"
              onClick={goToPrev}
            />
            <button
              className="absolute top-0 right-0 flex items-center justify-center text-white text-3xl w-1/2 h-full bg-transparent"
              onClick={goToNext}
            />
            <button
              className="absolute top-1/2 transform -translate-y-1/2 left-0 m-4 text-white text-3xl"
              onClick={goToPrev}
            >
              &lt;
            </button>
            <button
              className="absolute top-1/2 transform -translate-y-1/2 right-0 m-4 text-white text-3xl"
              onClick={goToNext}
            >
              &gt;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
