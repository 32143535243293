import React from "react";
import image from "../assets/goldcateringkragujevacmaincta.png";

export const CTABanner = () => {
  return (
    <div className="relative text-white">
      <img
        src={image}
        alt="Gold catering kragujevac"
        className="w-full h-full absolute top-0 left-0 bottom-0 -z-10 object-cover"
      />
      <div className="px-8 py-8 flex flex-col w-full h-[220px] md:h-[250px]">
        <div className="">
          <h2 className="text-[28px] md:tracking-wide font-regular pb-4 font-lexend">
            Zanima Vas više? Kontaktirajte nas
          </h2>
          <p className="font-lexend hidden md:block pb-4">
            Kontaktirajte nas danas tako što ćete nas pozvati na{" "}
            <span className="bg-accent font-lexend text-white py-1 px-2 rounded">
              +381 61 53 88 348
            </span>{" "}
            ili nam poslati email na adresu{" "}
            <span className="bg-accent font-lexend text-white py-1 px-2 rounded">
              info@goldcatering.rs
            </span>
          </p>
        </div>
        <button className="bg-accent px-4 py-2 rounded md:mt-0 font-lexend w-fit hover:font-bold w-[150px]">
          061 53 88 348
        </button>
      </div>
    </div>
  );
};
