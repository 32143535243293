import React from "react";
import { Title } from "./Title";
import { Cards } from "./Cards";

export const CateringOptions = () => {
  return (
    <div className="px-8" id="catering_options">
      <Title title="Ketering" rest="opcije" className="pt-4" />
      <p className="text-gray-600 text-lg font-lexend my-8">
        Sa pažljivo kreiranim menijima i izborom jela koji odražavaju različite
        ukuse i preferencije, garantujemo da ćemo Vašem događaju dati pravu
        notu. Naša ponuda obuhvata sve – od tradicionalnih specijaliteta do
        modernih kulinarskih kreacija, kako bi zadovoljili sve vaše goste.
      </p>
      <Cards />
    </div>
  );
};
