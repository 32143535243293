import React, { useState, useEffect } from "react";
import { HeroHeader } from "./components/HeroHeader";
import Loader from "./components/Loader";
import { NavBar } from "./components/NavBar";
import { OurStory } from "./components/OurStory";
import { Footer } from "./components/Footer";
import { CTABanner } from "./components/CtaBanner";
import { Gallery } from "./components/Gallery";
import ScrollToTop from "./components/ScrollToTop";
import { CateringOptions } from "./components/CateringOptions";
import { TendeDetail } from "./components/TendeDetail";
import ReactGA from "react-ga4";

ReactGA.initialize("G-44RHW6C89H");

function App() {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Homepage" });
  }, []);

  return (
    <div className="relative">
      {showLoader ? (
        <Loader />
      ) : (
        <>
          <NavBar />
          <HeroHeader />
          <OurStory />
          <div className="mt-8" />
          <CateringOptions />
          <div className="mt-8" />
          <TendeDetail />
          <CTABanner />
          <Gallery />
          <ScrollToTop />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
