import React from "react";
import classNames from "classnames";

export const Title = ({ title, rest, className }) => {
  return (
    <h1
      className={classNames(
        "text-5xl font-extralight font-lexend text-gray-800 mb-4",
        className
      )}
    >
      <span className="font-whisper text-accent text-7xl">{title}</span> {rest}
    </h1>
  );
};
