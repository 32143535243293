import React from "react";
import stars from "../assets/stars.png";

export const HeroHeader = () => {
  return (
    <div
      className="relative bg-cover bg-center h-screen"
      style={{ backgroundImage: "url('/assets/HeroHeader.png')" }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative text-white flex flex-col items-center justify-center h-screen">
        <img
          src={stars}
          alt="Gold Catering Kragujevac"
          className="w-[184px] h-[37px]"
        />
        <h1 className="text-8xl font-whisper text-accent text-center">
          Gold Catering
        </h1>
        <p className="mt-4 text-xl mx-8 text-center font-lexend text-2xl max-w-[650px] my-4">
          KETERING SERVIS SPECIJALIZOVAN ZA VAŠE POSEBNE MOMENTE
        </p>
        <a
          href="tel:0615388348"
          className="mt-4 px-4 py-2 bg-black border border-white hover:bg-accent font-medium rounded z-10"
        >
          061 53 88 348
        </a>
      </div>
    </div>
  );
};
