import React from "react";
import logo from "../assets/goldcateringkragujevacmainlogo.png";
import { FaFacebookF, FaInstagram, FaEnvelope, FaPhone } from "react-icons/fa";

export const NavBar = () => {
  return (
    <nav className="flex items-center justify-between px-4 md:px-8 bg-[rgba(0,0,0,0.5)] absolute top-0 left-0 right-0 z-10">
      <div className="flex items-center">
        <img
          src={logo}
          alt="Gold catering Kragujevac"
          className="h-[70px] w-[70px] mr-4 md:mr-8"
        />
      </div>
      <div className="hidden md:flex">
        <a
          href="#catering_options"
          className="px-4 text-white hover:bg-accent rounded hover:text-white font-medium -mt-4 pt-4 -mb-4 pb-4 mr-6"
        >
          Ketering
        </a>
        <a
          href="#tende_detail"
          className="px-4 text-white hover:bg-accent rounded hover:text-white font-medium -mt-4 pt-4 -mb-4 pb-4 mr-6"
        >
          Tende
        </a>
        <a
          href="#our_story"
          className="px-4 text-white hover:bg-accent rounded hover:text-white font-medium -mt-4 pt-4 -mb-4 pb-4"
        >
          O Nama
        </a>
      </div>
      <div className="hidden md:flex items-center gap-2 text-white font-light align-middle">
        <a
          href="https://www.facebook.com/goldcateringkg"
          className="hover:text-accent mr-4"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.instagram.com/goldcateringkg/?igsh=MTE3cW4xbWp5bHRqaA%3D%3D&utm_source=qr&fbclid=IwAR1ye-RpDdOjkebKZCaQVsu_L_0YdjKdGPx-fMx4gQMEskLlNhuY-q60Lec"
          className="hover:text-accent mr-4"
        >
          <FaInstagram />
        </a>
        <a
          href="mailto:info@goldcatering.rs"
          className="hidden lg:flex hover:text-accent flex items-center cursor-pointer"
        >
          <FaEnvelope className="mr-1" />
          <span>info@goldcatering.rs</span>
        </a>
        <a
          href="tel:0615388348"
          className="hover:text-accent flex items-center cursor-pointer"
        >
          <FaPhone className="mr-1 ml-2" />
          <span>061 53 88 348</span>
        </a>
      </div>
      <a
        href="tel:0615388348"
        className="hover:text-accent flex items-center md:hidden text-white"
      >
        <FaPhone className="mr-1 ml-2" />
        <span>061 53 88 348</span>
      </a>
    </nav>
  );
};
