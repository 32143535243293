import React, { useState, useRef } from "react";
import video from "../assets/goldcateringkragujevacpromovideo.mp4";
import { FaPlay } from "react-icons/fa";
import { Title } from "./Title";

export const OurStory = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const toggleVideo = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div
      className="flex flex-col md:flex-row items-center justify-center bg-gray-100 py-16 md:h-screen"
      id="our_story"
    >
      <div className="md:w-1/2 md:pr-4 px-8">
        <Title title="Naša" rest="priča" />
        <p className="text-gray-600 text-lg mb-4 font-lexend my-8">
          Mi smo <span className="text-accent">Gold Catering</span>, bavimo se
          keteringom i izdavanjem gazebo tendi za sve vrste proslava, kao što su
          rodjendani, slave, svadbe, poslovne prilike. Naš cilj je da vam
          pružimo vrhunsku uslugu, ukusnu i svežu hranu, elegantan i prostran
          ambijent i profesionalno i ljubazno osoblje. Spremni ste da učinite
          Vaše posebne trenutke nezaboravnim? Kontaktirajte nas danas tako što
          ćete nam pisati na email{" "}
          <span className="text-accent">info@goldcatering.rs</span> ili nas
          pozvati na <span className="text-accent">+381 61 53 88 348</span>.
        </p>
        <div className="mb-12">
          <a
            href="tel:0615388348"
            className="border border-accent text-accent px-4 py-2 rounded font-medium hover:bg-accent hover:text-white"
          >
            061 53 88 348
          </a>
        </div>
      </div>
      <div className="w-full md:w-1/2 md:pr-8">
        <div className="relative w-full h-96 md:rounded-full overflow-hidden shadow-lg">
          <video
            className="absolute inset-0 w-full h-full object-cover"
            src={video}
            onClick={toggleVideo}
            ref={videoRef}
          >
            Your browser does not support the video tag.
          </video>
          {!isPlaying ? (
            <div
              className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center cursor-pointer"
              onClick={toggleVideo}
            >
              <button className="w-16 h-16 rounded-xl bg-gray-800 bg-opacity-75 flex items-center justify-center">
                <FaPlay className="w-8 h-8 text-white" />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
